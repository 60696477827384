<template>
	<div
		v-if="products.length"
		:class="products.length < 3 ? 'no-slide' : ''"
		class="products__slider"
	>
		<div class="products__slider__left">
			<button aria-label="slide-left" type="button" @click="slide('left')">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path
						d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
				</svg>
			</button>
		</div>
		<div class="products__slider__wrapper">
		<!-- TODO :is="COMPONENT" for dynamic import  -->
			<ProductCard :products="products" />
		</div>
		<div class="products__slider__right">
			<button aria-label="slide-right" type="button" @click="slide('right')">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path
						d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue"

export default {
	name: "ProductSlider",
	components: {ProductCard},
	props: {
		products : {
			required: true
		}
	},
	methods: {
		slide: (direction) => {
			const productsWidth = document.querySelector('.products__slider__wrapper .card').getBoundingClientRect().width
			const slider = document.querySelector('.products__slider__wrapper .product_cards')
			const leftButton = document.querySelector('.products__slider__left button')
			const rightButton = document.querySelector('.products__slider__right button')

			if (direction === 'left') {
				slider.scrollLeft -= productsWidth
			} else if (direction === 'right') {
				slider.scrollLeft += productsWidth
			}

			slider.addEventListener('scroll', () => {
				if (slider.scrollLeft <= 10) {
					leftButton.style.opacity = '0'
					leftButton.style.pointerEvents = 'none'
				} else {
					leftButton.style.opacity = '1'
					leftButton.style.pointerEvents = 'all'
				}

				if (slider.scrollLeft + slider.offsetWidth + 10 >= slider.scrollWidth) {
					rightButton.style.opacity = '0'
					rightButton.style.pointerEvents = 'none'
				} else {
					rightButton.style.opacity = '1'
					rightButton.style.pointerEvents = 'all'
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

.products__slider {
	@include flex(space-between, center, $gap: 40px);
	width: 100%;
	@media screen and (max-width: 767.99px) {
		gap: 20px;
	}

	&.no-slide {
		.products__slider__left button, .products__slider__right button {
			display: none;
		}
	}

	&__left button, &__right button {
		@include flex(center, center);
		appearance: none;
		border: solid 1px $Brown;
		background-color: $t;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		transition: .2s;

		&:hover {
			background-color: $Brown;

			path {
				fill: $White;
			}
		}

		svg {
			@include fixed-size(30px);

			path {
				fill: $Brown;
			}
		}
	}

	&__left button {
		opacity: 0;
		pointer-events: none;
	}

	&__wrapper {
		@include flex(flex-start, flex-start);
		max-width: calc(100% - (100px + 80px));
		flex: 0 0 calc(100% - (100px + 80px));
		margin: 0 auto;
		@media screen and (max-width: 767.99px) {
			max-width: calc(100% - (100px + 40px));
			flex: 0 0 calc(100% - (100px + 40px));
		}

		:deep(section .product_cards){
			@include flex(flex-start, stretch);
			scroll-snap-align: center;
			width: 100%;
			overflow-x: auto;
			scroll-behavior: smooth;
			scroll-snap-type: x mandatory;
			padding: 10px 2px;
			scroll-padding: 2px;

			@include is-firefox() {
				scrollbar-width: thin;
				scrollbar-color: $t $t;
			}

			@media screen and (max-width: 575.99px) {
				$gap: 10px;
				gap: $gap;
			}
			@media screen and (min-width: 576px) and (max-width: 991.99px) {
				$gap: 20px;
				gap: $gap;
			}
			@media screen and (min-width: 992px) {
				$gap: 20px;
				gap: $gap;
			}
			@media screen and (min-width: 1199.99px) {
				$gap: 30px;
				gap: $gap;
			}

			&::-webkit-scrollbar {
				height: 0;
			}

			.card {
				scroll-snap-align: center;

				@media screen and (max-width: 575.99px) {
					$number: 1;
					$gap: 10px;
					scroll-snap-align: center;
					@include flex-child($number, $gap);
				}
				@media screen and (min-width: 576px) and (max-width: 991.99px)  {
					$number: 2;
					$gap: 20px;
					scroll-snap-align: start;
					@include flex-child($number, $gap);
				}
				@media screen and (min-width: 992px) {
					$number: 3;
					$gap: 20px;
					scroll-snap-align: start;
					@include flex-child($number, $gap);
				}
				@media screen and (min-width: 1199.99px) {
					$number: 4;
					$gap: 30px;
					@include flex-child($number, $gap);
				}
			}
		}
	}
}
</style>